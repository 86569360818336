import React, { FC, useState, useEffect } from "react";
import { Popover } from "antd";
import clsx from "clsx";
import styles from "./Booster.module.scss";

interface BoosterProps {
  overlayClassName?: string;
  children: Record<string, React.ReactNode>;
  blink?: boolean;
}

const Booster: FC<BoosterProps> = (props) => {
  const { children, overlayClassName, blink } = props;

  const [stop, setStop] = useState(false);

  const buttonClassname = clsx(
    styles.button,
    blink && !stop && styles.buttonBlink
  );

  useEffect(() => {
    setStop(false);
  }, [blink]);

  return (
    <Popover
      placement="bottom"
      content={children.content}
      overlayClassName={overlayClassName}
      trigger="click"
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className={buttonClassname}
        onClick={() => {
          if (blink) {
            setStop(true);
          }
        }}
      >
        <span className={styles.left}>{children.left}</span>
        <span className={styles.right}>{children.right}</span>
      </div>
    </Popover>
  );
};

export default Booster;
